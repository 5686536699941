import React, { useEffect, useState } from "react";
// import Card from "../UI/registrationCard";
import Card from "../UI/Card";
import "./registrationCard.css";
import { getRegistrationList } from "../Action/GetRegistrations";
import uniqBy from "lodash/uniqBy";
import blankpic from "./../blank-profile-picture-973460_960_720.webp"

const Index = ({ initialData }) => {

  const [data, setdata] = useState([]);

  useEffect(() => {
    get_data();

  }, []);

  const get_data = async () => {
    console.log("initialData registration :- ", initialData)
    const result = await getRegistrationList(initialData);

    const uniqueUsers = uniqBy(result, (obj) => obj.mobile);
    setdata(uniqueUsers);
  }
  console.log(initialData);
  return (
    <>
      {data.length > 0 && data.map((item) => {
        return (
          <>
            <div style={{ padding: "5px" }}>
              <Card>
                <div className="outer">
                  <div className="left">
                    <img className="image" src={(item.profile_pic_url) ? item.profile_pic_url : blankpic} />
                  </div>
                  <div className="right">
                    <div className="heading">
                      {item.name} {item.gautra}
                    </div>
                    <div className="relation">
                      <span>
                        {item.fathersName ? " पिता : " + item.fathersName : ""}
                      </span>
                      {" "}
                      |
                      <span>
                        {item.grandFathersName
                          ? " दादा : " + item.grandFathersName
                          : ""}
                      </span>
                    </div>
                    <div className="body">{item.village}</div>

                    <div className="footer">
                      {item.mobile}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </>
        );
      })}
      {data.length == 0 &&
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50vh', // Adjust this to control the vertical centering
            fontSize: 20,
            fontWeight: 'bold',
            color: 'green',
            margin:15,
          }}
        >
          आपके गाँव के बंद के आवेदन पूर्ण हो चुके।
          कोई नया आवेदन नहीं बचा।
          <br />
          धन्यवाद
        </div>}
    </>
  );
};

export default Index;
