import { getApiWrapper, postApiWrapper ,postRequest} from "./wrapper";
export const searchUser = async (data) => {
    const url = `https://demomeghwal.merasamaj.app/tasks/search.php`;
    console.log(data);
    const result = await postRequest(url, data);
    
    return result.search_results;
  }

export const dummyobj = async () =>{
  return [
    {
      "added_by": "964138137161",
      "age": null,
      "alive": "alive",
      "born": "1977-10-12",
      "created_at": "2022-10-12T09:13:09.983173+00:00",
      "curr_depth": "57",
      "email": null,
      "family_id": "1008178997964",
      "father_name": "पीथाराम",
      "gautra": "भुरट",
      "gender": "male",
      "generation": "50",
      "id": "100218860618",
      "is_mukhiya": false,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "60",
      "mobile": null,
      "name": "जलाराम",
      "onesignal_player_id": null,
      "pet_name": null,
      "pic_url": "",
      "registered_at": null,
      "registered_mobile": null,
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "497311236682",
      "subcaste": "",
      "uid": "100218860618",
      "updated_at": "2022-12-20T07:09:18.683513+00:00",
      "village": "झाक"
  },
  {
      "added_by": "1073378791498",
      "age": null,
      "alive": "alive",
      "born": null,
      "created_at": "2022-10-14T02:28:54.914290+00:00",
      "curr_depth": "54",
      "email": null,
      "family_id": "857477755990",
      "father_name": "बालाराम",
      "gautra": "भाटी/भाटीया",
      "gender": "male",
      "generation": "50",
      "id": "1043730870859",
      "is_mukhiya": false,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "55",
      "mobile": null,
      "name": "जालाराम",
      "onesignal_player_id": null,
      "pet_name": null,
      "pic_url": "",
      "registered_at": null,
      "registered_mobile": null,
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "None",
      "subcaste": "",
      "uid": "1043730870859",
      "updated_at": "2023-01-16T14:15:36.163411+00:00",
      "village": "लोहड़ी पण्डित जी"
  },
  {
      "added_by": "1006459385410",
      "age": null,
      "alive": "alive",
      "born": "1966-10-16",
      "created_at": "2022-10-16T17:45:00.401940+00:00",
      "curr_depth": "50",
      "email": null,
      "family_id": "282704941645",
      "gautra": "गोयल",
      "gender": "male",
      "generation": "50",
      "id": "282704941645",
      "is_mukhiya": true,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "51",
      "mobile": null,
      "name": "जलाराम",
      "onesignal_player_id": null,
      "pet_name": null,
      "pic_url": "",
      "registered_at": null,
      "registered_mobile": null,
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "682748226659",
      "subcaste": "",
      "uid": "282704941645",
      "updated_at": "2023-04-05T10:47:21.504582+00:00",
      "village": "नेतासर फलसुंड"
  },
  {
      "added_by": "195958766121",
      "age": null,
      "alive": "alive",
      "born": null,
      "created_at": "2022-10-16T18:09:48.903460+00:00",
      "curr_depth": "52",
      "email": null,
      "family_id": "283346763337",
      "father_name": "खंगाराराम",
      "gautra": "बल/बलवाडिया/बुला",
      "gender": "male",
      "generation": "50",
      "id": "794733824077",
      "is_mukhiya": false,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "52",
      "mobile": null,
      "name": "जालाराम",
      "onesignal_player_id": null,
      "pet_name": null,
      "pic_url": "",
      "registered_at": null,
      "registered_mobile": null,
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "None",
      "subcaste": "",
      "uid": "794733824077",
      "updated_at": "2023-05-24T09:28:28.249431+00:00",
      "village": "मिठौड़ा"
  },
  {
      "added_by": "9387402983",
      "age": null,
      "alive": "alive",
      "born": null,
      "created_at": "2022-10-18T09:28:24.963334+00:00",
      "curr_depth": "51",
      "email": null,
      "family_id": "186958072398",
      "father_name": "कानाराम",
      "gautra": "पूनङ",
      "gender": "male",
      "generation": "50",
      "id": "237009991758",
      "is_mukhiya": false,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "51",
      "mobile": null,
      "name": "जालाराम",
      "onesignal_player_id": null,
      "pet_name": null,
      "pic_url": "",
      "registered_at": null,
      "registered_mobile": null,
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "None",
      "subcaste": "",
      "uid": "237009991758",
      "updated_at": "2023-05-24T09:28:28.249493+00:00",
      "village": "कलावास"
  },
  {
      "added_by": "709142671927",
      "age": null,
      "alive": "alive",
      "born": "1986-10-20",
      "created_at": "2022-10-20T15:59:31.664662+00:00",
      "curr_depth": "51",
      "email": null,
      "family_id": "763922753104",
      "father_name": "बंशीलाल",
      "gautra": "कङेला",
      "gender": "male",
      "generation": "50",
      "id": "202951595088",
      "is_mukhiya": false,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "51",
      "mobile": null,
      "name": "जालाराम",
      "onesignal_player_id": null,
      "pet_name": null,
      "pic_url": "",
      "registered_at": null,
      "registered_mobile": null,
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "434230732368",
      "subcaste": "",
      "uid": "202951595088",
      "updated_at": "2023-05-24T09:28:28.249554+00:00",
      "village": "ओसियां"
  },
  {
      "added_by": "1051009910355",
      "age": null,
      "alive": "alive",
      "born": null,
      "created_at": "2022-10-25T15:34:43.030638+00:00",
      "curr_depth": "51",
      "email": null,
      "family_id": "268038519380",
      "father_name": "लुम्बाराम",
      "gautra": "बेघङ/बेगड़",
      "gender": "male",
      "generation": "50",
      "id": "601397444180",
      "is_mukhiya": false,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "52",
      "mobile": null,
      "name": "जालाराम",
      "onesignal_player_id": null,
      "pet_name": null,
      "pic_url": "",
      "registered_at": null,
      "registered_mobile": null,
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "520013374142",
      "subcaste": "",
      "uid": "601397444180",
      "updated_at": "2023-03-07T13:38:48.260583+00:00",
      "village": "बासनी भाटिया"
  },
  {
      "added_by": "136055048919",
      "age": null,
      "alive": "dead",
      "born": null,
      "created_at": "2022-10-27T11:38:51.843271+00:00",
      "curr_depth": "53",
      "email": null,
      "family_id": "47156146926",
      "father_name": "हंसाराम",
      "gautra": "धूमङा",
      "gender": "male",
      "generation": "50",
      "id": "1022567122517",
      "is_mukhiya": false,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "55",
      "mobile": null,
      "name": "जालाराम",
      "onesignal_player_id": null,
      "pet_name": null,
      "pic_url": "",
      "registered_at": null,
      "registered_mobile": null,
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "None",
      "subcaste": "",
      "uid": "1022567122517",
      "updated_at": "2023-03-18T17:49:01.884577+00:00",
      "village": "कल्याणपुर"
  },
  {
      "added_by": "122600308440",
      "age": null,
      "alive": "dead",
      "born": null,
      "created_at": "2022-10-27T17:57:26.440623+00:00",
      "curr_depth": "52",
      "email": null,
      "family_id": "899160535293",
      "father_name": "जोगाराम",
      "gautra": "भाटी/भाटीया",
      "gender": "male",
      "generation": "50",
      "id": "897820084310",
      "is_mukhiya": false,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "54",
      "mobile": null,
      "name": "जालाराम",
      "onesignal_player_id": null,
      "pet_name": null,
      "pic_url": "",
      "registered_at": null,
      "registered_mobile": null,
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "186833746518",
      "subcaste": "",
      "uid": "897820084310",
      "updated_at": "2023-04-21T11:28:17.315489+00:00",
      "village": "रामदेवपुरा नागाणा"
  },
  {
      "added_by": "501911989799",
      "age": null,
      "alive": "dead",
      "born": "",
      "created_at": "2022-10-30T10:00:57.584514+00:00",
      "curr_depth": "50",
      "email": null,
      "family_id": "824604350040",
      "gautra": "भाटी/भाटीया",
      "gender": "male",
      "generation": "50",
      "id": "824604350040",
      "is_mukhiya": false,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "53",
      "mobile": null,
      "name": "जालुराम",
      "onesignal_player_id": null,
      "pet_name": "",
      "pic_url": "",
      "registered_at": null,
      "registered_mobile": null,
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "None",
      "subcaste": "",
      "uid": "824604350040",
      "updated_at": "2023-05-24T09:28:28.249816+00:00",
      "village": "विजयनगर"
  },
  {
      "added_by": "804130895446",
      "age": null,
      "alive": "alive",
      "born": "1975",
      "created_at": "2022-11-01T00:31:44.154811+00:00",
      "curr_depth": "53",
      "email": null,
      "family_id": "134768537177",
      "father_name": "पूनमाराम",
      "gautra": "पारंगी",
      "gender": "male",
      "generation": "50",
      "id": "617568505433",
      "is_mukhiya": false,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "54",
      "mobile": null,
      "name": "जालाराम",
      "onesignal_player_id": null,
      "pet_name": "",
      "pic_url": "",
      "registered_at": null,
      "registered_mobile": null,
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "883251774126",
      "subcaste": "",
      "uid": "617568505433",
      "updated_at": "2023-02-16T08:38:02.610603+00:00",
      "village": "बुड़ीवाड़ा"
  },
  {
      "added_by": "136055048919",
      "age": null,
      "alive": "alive",
      "born": null,
      "created_at": "2022-10-31T12:54:12.444885+00:00",
      "curr_depth": "50",
      "email": null,
      "family_id": "129818981465",
      "gautra": "पंवार/परमार",
      "gender": "male",
      "generation": "50",
      "id": "129818981465",
      "is_mukhiya": false,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "52",
      "mobile": null,
      "name": "जलाराम",
      "onesignal_player_id": null,
      "pet_name": null,
      "pic_url": "",
      "registered_at": null,
      "registered_mobile": null,
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "None",
      "subcaste": "",
      "uid": "129818981465",
      "updated_at": "2023-05-24T09:28:28.250786+00:00",
      "village": "माधवपुर"
  },
  {
      "added_by": "229605604407",
      "age": null,
      "alive": "alive",
      "born": "1996-11-01",
      "created_at": "2022-11-01T07:06:05.036783+00:00",
      "curr_depth": "56",
      "email": null,
      "family_id": "121367352485",
      "father_name": "हमीराराम",
      "gautra": "जोईया/जोहिया/जुईया",
      "gender": "male",
      "generation": "50",
      "id": "716286828633",
      "is_mukhiya": false,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "57",
      "mobile": null,
      "name": "जालाराम",
      "onesignal_player_id": null,
      "pet_name": null,
      "pic_url": "",
      "registered_at": null,
      "registered_mobile": null,
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "139294635053",
      "subcaste": "",
      "uid": "716286828633",
      "updated_at": "2023-01-30T20:11:14.535447+00:00",
      "village": "मेहरेरी"
  },
  {
      "added_by": "568324158550",
      "age": null,
      "alive": "alive",
      "born": "",
      "created_at": "2022-11-02T03:03:40.469301+00:00",
      "curr_depth": "55",
      "email": null,
      "family_id": "375223234075",
      "father_name": "खेताराम",
      "gautra": "देवपाल",
      "gender": "male",
      "generation": "50",
      "id": "1047752259162",
      "is_mukhiya": false,
      "koot_falan": "जल",
      "matching_uid": null,
      "max_depth": "55",
      "mobile": "7339717811",
      "name": "जालाराम",
      "onesignal_player_id": "99e8a562-cda2-4654-bec5-2692b8d401f9",
      "pet_name": "",
      "pic_url": "https://s3.ap-south-1.amazonaws.com/meghwalekikaran/profiles%2Ff6464b1b-7ab0-43c4-a832-9bbd67b62ddc.jpg",
      "registered_at": "2023-01-12T03:57:03.085042+00:00",
      "registered_mobile": "7339717811",
      "roles": [],
      "samaj": "MEGHWAL_EKIKARAN",
      "spouse_family_id": "461170611883",
      "subcaste": "",
      "uid": "1047752259162",
      "updated_at": "2023-01-12T18:51:55.780311+00:00",
      "village": "ढाणा नेवरी"
  }
  ]
}