import React, { useState, useEffect } from "react";
import image from "../blank-profile-picture-973460_960_720.webp";
import { useNavigate } from "react-router-dom";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { getTLFeed } from "../Action/GetTimelineFeed";
import './TimelineFeed.css'
import ObituaryCard from "../Forms/ObituaryCard";
import MarriageCard from "../Forms/MarriageCard";
import SocialEventCard from "../Forms/SocialEventCard";
import marriageImage from '../marriageIcon.jpg'
import religiousEventImage from '../religiousIcon.jpg'
import socialEventIcon from '../socialEventIcon.jpg'
import obituaryIcon from '../Screenshot2023_06_14_181022.jpg'


const iconStyle = {
  width: '60px',
  height: '60px',
  objectFit: 'cover',
  borderRadius: '50%',
}

function Timeline() {
  const navigate = useNavigate();
  const [timeline, setTimeline] = useState([]);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    const response = await getTLFeed();
    console.log(response);
    setTimeline(response);
  };

  const openForm = () => {
    navigate("/events");
  };

  return (
    <div className="App">
      <button onClick={openForm}>Create Form </button>
      <VerticalTimeline lineColor={"blue"}>
        {timeline && timeline.length>0 && timeline.map((t, i) => {
          // const contentStyle =
          //   i === 0
          //     ? { background: "rgb(33, 150, 243)", color: "#fff" }
          //     : undefined;
          const arrowStyle = { borderRight: "7px solid  rgb(33, 150, 243)" };

          return (
            <VerticalTimelineElement
              key={i}
              className="vertical-timeline-element--work"
              // contentStyle={contentStyle}
              contentArrowStyle={arrowStyle}
              date={t.startDate}
              icon =  {<img src={t.type=='social_event'?religiousEventImage:t.type=='marriage'?marriageImage:obituaryIcon} className="icon-image" />}
              iconStyle = {{ background: "rgb(33, 150, 243)", color: "#fff" }}
            >
              <div style={{textAlign:'left'}}>
               <span  className = 'date-pill'>{t.startDate} </span> 
                </div>
              {t.type == "social_event" ? (
                <SocialEventCard data={t} />
              ) : t.type == "obituary" ? (
                <ObituaryCard data={t} />
              ) : (
                <MarriageCard data={t} />
              )}
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
}

export default Timeline;
