import React, { useState, useEffect } from "react";
import { getNameList } from "../Action/GetNamesList";
import { getGautrasList } from "../Action/GetGautrasList";
import { searchUser } from "../Action/GetResultForSuggestedName";
import AutoComplete from "./AutoComplete1";

const SearchUser = (props) => {
    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [namelist, setnamelist] = useState();
    const [name, setname] = useState([]);
    const [gautra, setgautra] = useState();
    const [gautralist, setgautralist] = useState([]);

    const [suggestedResult, setSuggestedResult] = useState([]);

    console.log(props)
    const getSuggedtionNameList = async () => {
        const name_List = await getNameList();
        const gautra_List = await getGautrasList();
        setgautralist(gautra_List);
        console.log(gautra_List)
        setnamelist(name_List);
    };
    useEffect(() => {
        getSuggedtionNameList();

        // setTimeout(()=>{
        //   setData(['Apple', 'Banana', 'Cherry', 'Durian', 'Elderberry'])
        // },3000)
    }, []);

    const handleSubmit = async () => {
        setSuggestedResult([])
        console.log("hadle", gautra, name);
        let data = {
            name: name,
            gautra: gautra,
        }
        let result = await searchUser(data);
        console.log(result);
        setSuggestedResult(result)
    }
    const handleOptionSelect = (value) => {

        console.log(value)
        let d = props.metaData + '.graph_node_id';
        let obj = { "target": { "name": d, "value": value.id } };
        props.handleChange(obj)

        d = props.metaData + '.mobile';
        console.log("--> ",d)
        obj = { "target": { "name": d, "value": (value.registered_mobile) ? value.registered_mobile : value.mobile } };
        props.handleChange(obj);


        d = props.metaData + '.node_data';
        obj = { "target": { "name": d, "value": value } };
        props.handleChange(obj);
        d = props.metaData + '.image.url';
        obj = { "target": { "name": d, "value": value.pic_url } };
        props.handleChange(obj);
        d = props.metaData + '.display_name';
        obj = { "target": { "name": d, "value":  value.name } };
        props.handleChange(obj);

        setSuggestedResult([]);

        props.onRequestClose();

    };





    return (
        <div style={{overflow:"auto"}}>
            <h1>Autocomplete Example</h1>
            <h5>Enter name {name}</h5>
            <AutoComplete options={namelist} setValue={setname} />
            <h5>Enter gautra {gautra}</h5>
            <AutoComplete options={gautralist} setValue={setgautra} isgautra={true} />

            <button onClick={handleSubmit}>Search user</button>

            {suggestedResult.length > 0 && (
                <div>
                    {suggestedResult.map((option) => (
                        <div key={option.name || option} style={{ margin: 10, borderWidth: 1, borderColor: "#111" }} onClick={() => handleOptionSelect(option)}>
                            {option.name} , {option.registered_mobile},{option.pic_url}
                        </div>
                    ))}
                </div>
            )}


        </div>


    );
};

export default SearchUser;
