import React from 'react'
import Modal from '../UI/Modal'
import Image from '../card-preview.jpg'

const ImageModal = (props) => {
  return (
   <Modal  openModal={props.openModal} style= {{backgroundColor:'none'}} >
    <img src={Image} style={{width:'100%', height:'100%'}} />
   </Modal>
  )
}

export default ImageModal;