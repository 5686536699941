import React from "react";
import image from "../blank-profile-picture-973460_960_720.webp";
import "./ObituaryCardCss.css";

const ObituaryCard = (props) => {
  return (
    <>
      <div
        style={{
          background: "#606060",
          padding: "7px",
          borderRadius: "3px",
          marginBottom: "20px",
          fontWeight: "700",
          color: "white",
        }}
      >
        शोक संदेश
      </div>
      <div className="obituary">
        <img
          src={props.data.name.pic_url ? props.data.name.pic_url : image}
          alt="Obituary Image"
          className="obituary-image"
        />
        <h2 className="obituary-name">{props.data.name.name} </h2>
        <p className="obituary-description">{props.data.description}</p>
        <div>12th day</div>
        <div className="obituary-contact">
          <h3 className="obituary-contact-person">
            {props.data.contactPerson.name} {props.data.contactPerson.gautra}
          </h3>
          <p className="obituary-address">{props.data.address}</p>
        </div>
        
      </div>
      {/* <div classNameName="preview" style="height: 542px;">
        <div id="showtext">OBITUARY</div>
        <div classNameName="pictures">
          {" "}
          <img id="blah" src="images/imgPlaceholder.jpg" alt="" />{" "}
        </div>
        <div id="showtext2" style="font-size: 17px;">
          RAJESWARI VENKATRAMAN
        </div>
        <div id="datedata"> 12 Oct 1949 - 22 Sep 2021</div>
        <div>
          <img src="images/divider.jpg" classNameName="sperator" />
        </div>
        <div id="showtext3">
          (Retd. IIT, Chennai)
          <br /> With Profound Grief we inform that our beloved
          mother/grandmother passes away on 22 september, 2021. Aparna,
          Jayashree, Archita, Anish, Annadha, Ananth
          <br />{" "}
        </div>
        <div>
          <img src="images/divider.jpg" classNameName="sperator" />
        </div>
        <div id="mobiledata">+91 9999999999</div>
      </div> */}
    </>
  );
};

export default ObituaryCard;
