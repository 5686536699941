import { getRequest } from "./wrapper";

export const getRegistrationList = async (initialData) => {
  try {
    let village = (initialData.selected_node.village) ? initialData?.selected_node?.village : "";
    let NODE_URI = (initialData.NODE_URI) ? initialData.NODE_URI : "";
    console.log(NODE_URI,initialData);
    if (!initialData.NODE_URI) {
      return {}
    }

    const url = `${NODE_URI}/api/registrations?village=${village}`;
    let result = await getRequest(url, initialData.selected_node);

    return result && result.data && result.data.registrations ? result.data.registrations : {};
  } catch (error) {
    // Handle the error here, you can log it or perform any appropriate actions
    alert("An error occurred while fetching registration list:", error);
    return {}; // Return an empty object or some default value in case of error
  }
}
