import React, { useState } from "react";
import "./ObituaryCardCss.css";
import image from "../blank-profile-picture-973460_960_720.webp";
import imagePreview from '../card-preview.jpg'
import ImageModal from "../ImageModal";

const MarriageCard = (props) => {

  const [imageModal, setImageModal]  = useState(false);

  return (
    <>
    {imageModal?
      <ImageModal openModal={setImageModal}/>
      :''
      }
      <div class="obituary">
        <div style={{background:'red', padding:'7px', borderRadius:'3px', marginBottom:'20px', fontWeight:'700', color:' #FFD700'}}>शुभ विवाह</div>
        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <img
              src={props.data.name.pic_url?props.data.name.pic_url:image}
              alt="groom Image"
              style={{ height: "40px", width: "40px", borderRadius: "50%" }}
            />
           <span style={{color:'#e30b5d'}}>{props.data.name.name} </span>
          </div>
          <div style={{marginBottom:'20px', marginLeft:'10px', marginRight:'10px', color:'#808000', fontWeight:'700'}}>संग</div>
          <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
            <img
              src={props.data.bride.pic_url?props.data.bride.pic_url:image}
              alt="bride Image"
              style={{ height: "40px", width: "40px", borderRadius: "50%" }}
            />
            <span style={{color:'#e30b5d'}}>{props.data.bride.name} </span>
          </div>
        </div>
        {/* <h2 class="obituary-name">Show card Photo</h2> */}
        <div className="image-container" onClick={()=>{setImageModal(true)}}>
        <img  className= 'card-preview'  src = {imagePreview}   />
        <div className="text-over-image">
          {/* <img src={eyeImage} className="eye-image"/> */}
          <span style={{paddingTop:'20px'}}>कार्ड देखें </span>
          </div>
       </div> 
        <p class="obituary-description">
        {props.data.description}
        </p>
        <div class="obituary-contact">
          <h3 class="obituary-contact-person">{props.data.contactPerson.name} {props.data.contactPerson.gautra}</h3>
          <p class="obituary-address">{props.data.address}</p>
        </div>
      </div>
    </>
  );
};

export default MarriageCard;
