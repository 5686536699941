import React, { useState } from 'react';

const Autocomplete = ({ options, setValue, isgautra = false }) => {
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    // Filter the options based on the input value
    let filtered = []
    if (isgautra) {
      filtered = options.filter((option) =>
        option.display_name.toLowerCase().includes(value.toLowerCase())
      );

    }
    else {
      filtered = options.filter((option) =>
        option.toLowerCase().includes(value.toLowerCase())
      );
    }
    setFilteredOptions(filtered);
  };

  const handleOptionSelect = (value) => {

    if (isgautra) {
      setInputValue(value.display_name);
      setValue(value.display_name);
    }
    else {
      setInputValue(value);
      setValue(value);
    }
    setFilteredOptions([]);
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Search..."
      />
      {filteredOptions.length > 0 && (
        <div>
          {filteredOptions.map((option) => (
            <div key={option.display_name || option} style={{margin:10, borderWidth:1,borderColor:"#111"}} onClick={() => handleOptionSelect(option)}>
              {isgautra ? option.display_name : option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
