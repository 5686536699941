
const helpers = {
    httpGet: async function (url, Token) {
        try {
            const data = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Token " + Token
                }
            });
            return await data.json();
        } catch (error) {
            console.error(error);
        }
    },
    httpPost: async function (url, Token, data) {
        try {
            const DATA = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Token " + Token
                },
                body: JSON.stringify(data),
            });
            return await DATA.json();
        } catch (error) {
            console.error(error);
        }
    }
};
export default helpers;