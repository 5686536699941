import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css'
import React, { useState } from 'react';
import EventForm from './Forms/EventForm';
import TimeLine from './Timeline-feed/index'
import RootLayout from './RouterHelper/root';
import ErrorPage from './RouterHelper/error';
import Registrations from "./Registrations"


const router = (initialData) => createBrowserRouter([
  {
    path: '/',
    element: <RootLayout initialData={initialData} />,
    errorElement: <ErrorPage initialData={initialData} />,
    children: [
      { index: true, element: <EventForm initialData={initialData} /> },
      {
        path: 'events',
        element: <EventForm />,
      },
    ],
  },
  {
    path: '/event',
    element: <RootLayout initialData={initialData} />,
    errorElement: <ErrorPage initialData={initialData} />,
    children: [
      { index: true, element: <EventForm initialData={initialData} /> },
    ]
  },
  {
    path: '/timeline',
    element: <RootLayout initialData={initialData} />,
    errorElement: <ErrorPage initialData={initialData} />,
    children: [
      { index: true, element: <TimeLine /> },
    ]
  },
  {
    path: '/registrations',
    element: <RootLayout initialData={initialData} />,
    errorElement: <ErrorPage initialData={initialData} />,
    children: [
      { index: true, element: <Registrations initialData={initialData} /> },
    ]
  }
]);

function App() {

  const [initialData, setInitialData] = useState({ selected_node: { name: "",village:"" } });
  const [dataLoaded, setdataLoaded] = useState(false);

  const updateInitialData = (data) => {
    console.log("initail data ", data);
    setInitialData(data);
    setdataLoaded(true);
  };
  // Attach the updateSelectedNode function to the window object
  window.updateInitialData = updateInitialData;

  return (
    (dataLoaded) ? (<RouterProvider router={router(initialData)} />) : (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '50vh', // Adjust this to control the vertical centering
          fontSize: 20,
          fontWeight: 'bold',
          color: 'green',
          margin: 15,
        }}
      >
        आपकी जानकारी प्राप्त कर जा रही है।
        <br />
        कृपया इंतज़ार करें 🙏
      </div>
    )

  );
}

export default App;
