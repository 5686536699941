import React, {useState} from "react";
import image from '../blank-profile-picture-973460_960_720.webp'
import './ObituaryCardCss.css'
import imagePreview from '../card-preview.jpg'
import eyeImage from '../view.png'
import ImageModal from "../ImageModal";

const SocialEventCard = (props) => {
  const [imageModal, setImageModal] = useState(false);
  return (
    <>
      <>
      {imageModal?
      <ImageModal openModal={setImageModal}/>
      :''
      }
      <div style={{background:'#2b50c8', padding:'7px', borderRadius:'3px', marginBottom:'20px', fontWeight:'700', color:' #FFFFFF'}}>सामाजिक कार्यक्रम</div>
      <div class="obituary">
        <div class="obituary-name">{props.data.name}</div>
        {/* <img
          src={props.data.image}
          alt="Obituary Image"
          class="obituary-image"
        /> */}
       <div className="image-container" onClick={()=>{setImageModal(true)}}>
        <img  className= 'card-preview'  src = {imagePreview}   />
        <div className="text-over-image">
          {/* <img src={eyeImage} className="eye-image"/> */}
          <span style={{paddingTop:'20px'}}>कार्ड देखें </span>
          </div>
       </div> 
        {/* <h2 class="obituary-name">John Doe</h2> */}
        <p class="obituary-description">
          {props.data.description}
        </p>
        <div class="obituary-contact">
          <h3 class="obituary-contact-person">{props.data.contactPerson.name} {props.data.contactPerson.gautra}</h3>
          <p class="obituary-address">{props.data.address}</p>
        </div>
      </div>
    </>
    </>
  );
};

export default SocialEventCard;
