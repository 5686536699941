import React, { forwardRef, useState } from 'react';
import Modal from 'react-modal';
import AWS from 'aws-sdk';
import './EventForm.css'
import SearchUser from './../SearchUser/index';
import { postEvent } from "../Action/EventFormAction";



const AWSS3_OPTIONS = {
  bucket: 'bhavnagarpaliwal',
  region: 'ap-south-1',
  accessKey: 'AKIA3CE3LSGZ4JWSNP77',
  secretKey: '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',
}

const EventForm = (initialData) => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [metaData, setMetaData] = useState("");
  const [formData, setFormData] = useState({
    type: '',
    name: '',
    start_date: '',
    end_date: '',
    imageFile: null,
    event_invitation_image: {
      url: '',
      title: '',
    },
    event_location: '',
    description: '',
    organizers: [
      {
        graph_node_id: '',
        node_data: {},
        role: '',
        mobile: "",
        display_name: '',
        image: {
          url: '',
          title: '',
        },

      },
    ],
    contact_persons: [
      {
        graph_node_id: '',
        node_data: {},
        role: '',
        mobile: "",
        display_name: '',
        image: {
          url: '',
          title: '',
        },

      },
    ],
    subevents: [
      {
        name: '',
        date: '',
        description: '',
      },
    ],
    event_data: {
      DEATH: {
        death_of: {
          graph_node_id: '',
          mobile: "",
          node_data: {},
          image: {
            url: '',
            title: '',
          },
          display_name: '',
        },
      },
      WEDDING: {
        side: '',
        bride: {
          graph_node_id: '',
          node_data: {},
          display_name: '',
          image: {
            url: '',
            title: '',
          },
          mobile: "",
        },
        groom: {
          graph_node_id: '',

          mobile: "",
          node_data: {},
          display_name: '',
          image: {
            url: '',
            title: '',
          },
        },
        cover_image: {
          url: '',
          title: '',
        },
      },
      SOCIAL: {
        organization: {
          org_id: '',
          name: '',
        },
      },
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(e, name, value);
    let prevdata = formData;
    let data = prevdata, prev = prevdata;
    let seprated_name = name.split('.')

    for (let i = 0; i < seprated_name.length; i += 1) {
      prev = data;
      let meta = seprated_name[i];
      console.log(meta, seprated_name)
      if (meta[meta.length - 1] == ']') {

        let meta_split = meta.split('[');
        data = data[meta_split[0]]
        prev=data;
        data=data[meta_split[1].split(']')[0]];
        console.log("\n-------------\n",meta_split,meta,data," \n\n")
      }
      else
        data = data[seprated_name[i]];
      console.log(data, prev, meta);
      // if (!data) {
      //   console.log("\n\n\n returning \n\n\n")
      //   return;
      // }
    }
    prev[seprated_name[seprated_name.length - 1]] = value;


    setFormData((prevFormData) => ({
      ...prevFormData,
      // [name]: value,
    }));
  };
  const handleImageChange = async (e, inputName) => {
    const file = e.target.files[0];
    console.log(file, inputName);
    let image_URL = "";
    if (file) {
      try {
        const s3 = new AWS.S3({
          accessKeyId: AWSS3_OPTIONS.accessKey,
          secretAccessKey: AWSS3_OPTIONS.secretKey,
          region: AWSS3_OPTIONS.region,
        });

        const bucketName = AWSS3_OPTIONS.bucket;
        const keyPrefix = 'events_test/';
        const params = {
          Bucket: bucketName,
          Key: `${keyPrefix}${file.name}`,
          Body: file,
          ACL: 'public-read',
        };

        const { Location } = await s3.upload(params).promise();
        image_URL = Location;

        console.log('Image uploaded successfully. URL:', Location);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
    handleChange(e = { 'target': { "name": inputName, "value": image_URL } })

    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   imageFile: file,
    // }));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleUserSelect = (user) => {
    console.log(user);
    // closeModal();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Upload image to AWS S3
    // Handle the rest of the form submission and data storage
    // ...  
    if(formData.type=='SOCIAL')
    {
      formData.event_data.WEDDING={};
      formData.event_data.DEATH={};
    }

    if(formData.type=='WEDDING')
    {
      formData.event_data.DEATH={};
      formData.event_data.SOCIAL={};
    }
    if(formData.type=='DEATH')
    {
      formData.event_data.WEDDING={};
      formData.event_data.SOCIAL={};
    }
    let result = await postEvent(formData);
    console.log("\n\n\n",formData,result);
  };
  const handleUserSearch = async (e) => {
    console.log("hoewjiorjweo", e)
    setMetaData(e);
    setIsModalOpen(true);

    return;
  };

  return (
    <form onSubmit={handleSubmit}>
      <label style={{ margin: 10 }}>
        Event Type:
        <select
          style={{ margin: 15, width: "80%" }}
          name="type"
          value={formData.type}
          onChange={handleChange}

        >
          <option value="">Select Event Type</option>
          <option value="SOCIAL">Social</option>
          <option value="WEDDING">Wedding</option>
          <option value="DEATH">Death</option>
        </select>
      </label>
      <br /><br />

      {formData.type === 'SOCIAL' && (

        <label
          style={{ margin: 10 }}>
          Social Event Name:
          <input
            style={{ margin: 15, width: "80%" }}
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}

          />
          <br /><br /></label>


      )}

      <label
        style={{ margin: 10 }}>
        Start Date:
        <input
          style={{ margin: 15, width: "80%" }}
          type="date"
          name="start_date"
          value={formData.start_date}
          onChange={handleChange}

        />
        <br /><br /></label>

      <label
        style={{ margin: 10 }}>
        End Date:
        <input
          style={{ margin: 15, width: "80%" }}
          type="date"
          name="end_date"
          value={formData.end_date}
          onChange={handleChange}

        />
        <br /><br /></label>
      <label
        style={{ margin: 10 }}>
        Event Invitation  Image:
        {formData.event_invitation_image.url && (
          <div>
            <img src={formData.event_invitation_image.url} height={200} width={250} />

          </div>
        )}

        <input
          style={{ margin: 15, width: "80%" }}
          type="file"
          name="event_invitation_image.url"
          onChange={(e) => handleImageChange(e, "event_invitation_image.url")}
        />
        <br /><br /></label>

      <label
        style={{ margin: 10 }}>
        Event Location:
        <input
          style={{ margin: 15, width: "80%" }}
          type="text"
          name="event_location"
          value={formData.event_location}
          onChange={handleChange}

        />
        <br /><br /></label>

      <label
        style={{ margin: 10 }}>
        Description:
        <textarea
          style={{ margin: 15, width: "80%" }}

          name="description"
          value={formData.description}
          onChange={handleChange}

        />
        <br /><br /></label>

      {/* Organizers */}
      {formData.organizers.map((organizer, index) => (
        <div key={index}>

          <label
            style={{ margin: 10 }}>
            Organizer Name:
            {formData.organizers[index].image.url && (
              <div>
                <img src={formData.organizers[index].image.url}  />

              </div>
            )}

            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name={`organizers[${index}].display_name`}
              value={organizer.display_name}
              // onChange={handleChange}
              onClick={() => handleUserSearch(`organizers[${index}]`)}

            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Organizer Role:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name={`organizers[${index}].role`}
              value={organizer.role}
              onChange={handleChange}

            />
            <br /><br /></label>


        </div>
      ))}
      {/* Contact Persons */}
      {formData.contact_persons.map((contactPerson, index) => (
        <div key={index}>

          <label
            style={{ margin: 10 }}>
            Contact Person Name:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name={`contact_persons[${index}].graph_node_id`}
              value={contactPerson.display_name}
              // onChange={handleChange}
              onClick={() => handleUserSearch(`contact_persons[${index}]`)}

            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Contact Person Role:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name={`contact_persons[${index}].role`}
              value={contactPerson.role}
              onChange={handleChange}

            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Contact Person Mobile:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name={`contact_persons[${index}].mobile`}
              value={contactPerson.mobile}
              onChange={handleChange}

            />
            <br /><br /></label>


        </div>
      ))}
      {/* Subevents */}
      {formData.subevents.map((subevent, index) => (
        <div key={index}>

          <label
            style={{ margin: 10 }}>
            Subevent Name:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name={`subevents[${index}].name`}
              value={subevent.name}
              onChange={handleChange}

            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Subevent Date:
            <input
              style={{ margin: 15, width: "80%" }}
              type="date"
              name={`subevents[${index}].date`}
              value={subevent.date}
              onChange={handleChange}

            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Subevent Description:
            <textarea
              style={{ margin: 15, width: "80%" }}

              name={`subevents[${index}].description`}
              value={subevent.description}
              onChange={handleChange}

            />
            <br /><br /></label>


        </div>
      ))}
      {/* Event-specific fields based on event type */}
      {formData.type === 'DEATH' && (
        <div>

          <label
            style={{ margin: 10 }}>
            Name of the Deceased:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name="event_data.DEATH.death_of.display_name"
              value={formData.event_data.DEATH.death_of.display_name}
              // onChange={handleChange}
              onClick={() => handleUserSearch(`event_data.DEATH.death_of`)}


            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Person  Image:
            {formData.event_data.DEATH.death_of.image.url && (
              <div>
                <img src={formData.event_data.DEATH.death_of.image.url} height={200} width={250} />

              </div>
            )}

            <input
              style={{ margin: 15, width: "80%" }}
              type="file"
              name="event_data.DEATH.death_of.image.url"
              onChange={(e) => handleImageChange(e, "event_data.DEATH.death_of.image.url")}
            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Display Name of the Deceased:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name="event_data.DEATH.death_of.display_name"
              value={formData.event_data.DEATH.death_of.display_name}
              onChange={handleChange}

            />
            <br /><br /></label>


        </div>
      )}
      {formData.type === 'WEDDING' && (
        <div>

          <label
            style={{ margin: 10 }}>
            Side (GIRL/BOY):
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name="event_data.WEDDING.side"
              value={formData.event_data.WEDDING.side}
              onChange={handleChange}

            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Bride:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name="event_data.WEDDING.bride.display_name"
              value={formData.event_data.WEDDING.bride.display_name}
              // onChange={handleChange}
              onClick={() => handleUserSearch(`event_data.WEDDING.bride`)}


            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Bride Display Name:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name="event_data.WEDDING.bride.display_name"
              value={formData.event_data.WEDDING.bride.display_name}
              onChange={handleChange}

            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Bride  Image:
            {formData.event_data.WEDDING.bride.image.url && (
              <div>
                <img src={formData.event_data.WEDDING.bride.image.url} height={200} width={250} />

              </div>
            )}

            <input
              style={{ margin: 15, width: "80%" }}
              type="file"
              name={`event_data.WEDDING.bride.image.url`}
              onChange={(e) => handleImageChange(e, `event_data.WEDDING.bride.image.url`)}

            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Bride Image Title:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name="event_data.WEDDING.bride.image.title"
              value={formData.event_data.WEDDING.bride.image.title}
              onChange={handleChange}

            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Groom:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name="event_data.WEDDING.groom.display_name"
              value={formData.event_data.WEDDING.groom.display_name}
              // onChange={handleChange}
              onClick={() => handleUserSearch(`event_data.WEDDING.groom`)}

            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Groom Display Name:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name="event_data.WEDDING.groom.display_name"
              value={formData.event_data.WEDDING.groom.display_name}
              onChange={handleChange}

            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Groom  Image:
            {formData.event_data.WEDDING.groom.image.url && (
              <div>
                <img src={formData.event_data.WEDDING.groom.image.url} height={200} width={250} />

              </div>
            )}

            <input
              style={{ margin: 15, width: "80%" }}
              name="event_data.WEDDING.groom.image.url"
              type="file"
              onChange={(e) => handleImageChange(e, "event_data.WEDDING.groom.image.url")}


            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Groom Image Title:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name="event_data.WEDDING.groom.image.title"
              value={formData.event_data.WEDDING.groom.image.title}
              onChange={handleChange}

            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Cover  Image:
            {formData.event_data.WEDDING.cover_image.url && (
              <div>
                <img src={formData.event_data.WEDDING.cover_image.url} height={200} width={250} />

              </div>
            )}
            <input
              style={{ margin: 15, width: "80%" }}
              name="event_data.WEDDING.cover_image.url"
              type="file"
              onChange={(e) => handleImageChange(e, "event_data.WEDDING.cover_image.url")}


            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Cover Image Title:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name="event_data.WEDDING.cover_image.title"
              value={formData.event_data.WEDDING.cover_image.title}
              onChange={handleChange}

            />
            <br /><br /></label>


        </div>
      )}
      {formData.type === 'SOCIAL' && (
        <div>

          <label
            style={{ margin: 10 }}>
            Organization ID:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name="event_data.SOCIAL.organization.org_id"
              value={formData.event_data.SOCIAL.organization.org_id}
              onChange={handleChange}

            />
            <br /><br /></label>



          <label
            style={{ margin: 10 }}>
            Organization Name:
            <input
              style={{ margin: 15, width: "80%" }}
              type="text"
              name="event_data.SOCIAL.organization.name"
              value={formData.event_data.SOCIAL.organization.name}
              onChange={handleChange}

            />
            <br /><br /></label>


        </div>
      )}
      <button type="submit">Submit</button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        overlayClassName="modal-overlay"
        className="modal-content"
      >
        {/* Content of the modal */}
        <SearchUser onRequestClose={closeModal} handleChange={handleChange} metaData={metaData} />
      </Modal>

    </form>
  );
};

export default EventForm;
